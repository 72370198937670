import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { DataSourcesActions } from '@store/data-sources';
import {
  selectAllFilesProcessedSuccessfully,
  selectAnyFileProcessedFailed,
  STORAGE_FILE_API_ACTIONS,
} from '@store/digi.me';
import { filter } from 'rxjs';
import { AddButtonComponent } from './add-button.component';

@Component({
  standalone: true,
  imports: [AddButtonComponent, CommonModule],
  selector: 'app-add-button-container',
  template: `<app-add-button (addSources)="this.addSources()"></app-add-button> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDropDownMenuContainerComponent {
  // TODO: Why is this functionality placed in a dropdown menu?
  anyFileProcessedFailed$ = this.store.select(selectAnyFileProcessedFailed);
  allFilesProcessedSuccessfully$ = this.store.select(selectAllFilesProcessedSuccessfully);

  constructor(
    private readonly store: Store,
    readonly destroyRef: DestroyRef,
  ) {
    this.allFilesProcessedSuccessfully$
      .pipe(
        takeUntilDestroyed(destroyRef),
        filter((allSuccess) => allSuccess),
      )
      .subscribe(() => this.store.dispatch(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesSucceeded()));

    this.anyFileProcessedFailed$
      .pipe(
        takeUntilDestroyed(destroyRef),
        filter((anyFailed) => anyFailed),
      )
      .subscribe(() => this.store.dispatch(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesFailed()));
  }

  public addSources(): void {
    this.store.dispatch(DataSourcesActions.addWizardRequested());
  }
}
