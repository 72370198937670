import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '@layout';
import { MenuItem } from './menu-item.model';

@Component({
  standalone: true,
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  imports: [CommonModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent<T extends MenuItem> {
  @Input({ required: true }) menu: T[] = [];
  @Input() rightArrow = false;
  @Output() selected = new EventEmitter<T>();

  select(item: T): void {
    this.selected.emit(item);
  }
}
