import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '@layout';

@Component({
  standalone: true,
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  imports: [ButtonComponent, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddButtonComponent {
  @Output() addSources = new EventEmitter<string | undefined>();
}
