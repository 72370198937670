@for (item of menu; track item.id) {
<app-button use="menu"
            type="navigation"
            [rightArrow]="rightArrow && !item.disabled"
            [svg]="item.icon"
            [label]="item.header"
            [customIconClass]="item.customIconClass"
            [working]="item.working ?? false"
            [disabled]="item.disabled ?? false"
            (clicked)="select(item)"></app-button>
}
